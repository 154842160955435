import { onMounted, ref, reactive, provide } from 'vue'
import mapboxgl from 'mapbox-gl'
import { store } from '@/store'
import { useRouter } from 'vue-router'
import { centroid } from '@turf/turf'

import foliage from '@/assets/foliage.png'
import graveyard from '@/assets/graveyard.png'
import okn from '@/assets/marker_okn.png'
import waterdrain from '@/assets/marker_water.png'
import burialground from '@/assets/marker_burialground.png'
import fish from '@/assets/marker_fish.png'

import { SOURCES_GET } from '@/store/actions/sources'

export function useMap (init) {
  const router = useRouter()
  const map = ref(null)
  const dataLoading = ref(false)
  const drawMode = ref(false)
  const layersFilter = ref([])
  const mapParams = reactive({
    hover: {
      id: null,
      source: null,
      sourceLayer: null
    },
    click: {
      id: null,
      source: null,
      sourceLayer: null
    },
    popup: null
  })
  const setQueryCenterMap = () => {
    const { lng, lat } = map.value.getCenter()
    const zoom = Math.round(map.value.getZoom() * 10) / 10
    router.push({
      query: {
        lng, lat, zoom
      }
    })
  }
  const featureActive = (feature = null) => {
    if (mapParams.click.id !== null) {
      map.value.setFeatureState(
        { ...mapParams.click },
        { clicked: false }
      )
    }
    if (feature === null) {
      mapParams.click = {
        id: null,
        source: null,
        sourceLayer: null
      }
      return
    }
    mapParams.click = {
      id: feature.id,
      source: feature.source,
      sourceLayer: feature.sourceLayer
    }
    map.value.setFeatureState(
      { id: feature.id, source: feature.source, sourceLayer: feature.sourceLayer },
      { clicked: true }
    )
  }
  const mapInit = onMounted(async () => {
    mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_TOKEN
    map.value = new mapboxgl.Map({
      container: init.container,
      style: process.env.VUE_APP_MAPBOX_STYLE,
      ...init.options,
      maxZoom: 20
    })
    const scale = new mapboxgl.ScaleControl({
      maxWidth: 120,
      unit: 'metric'
    })
    map.value.addControl(scale)
    map.value.on('moveend', () => {
      setQueryCenterMap()
    })
    map.value.on('load', () => {
      const clickOnObject = (e, feature, options) => {
        const { geometry, properties } = feature
        const popup = options.popup
        const coordinates = geometry.type === 'Point'
          ? geometry.coordinates
          : centroid(feature).geometry.coordinates
        if (options.click === 'zoom') {
          map.value.flyTo({
            center: coordinates,
            zoom: 18
          })
        }
        if (mapParams.popupClick) {
          mapParams.popupClick.remove()
        }
        if (options.click && popup) {
          featureActive(feature)
          const description = []
          const format = (val, n, s = 0) => Math.round(val * 10 ** n) / (10 ** n) / (10 ** s)
          Object.keys(options.popup).forEach((prop, index) => {
            if (prop === 'coordinates') {
              description.push(`<div class="popupPower__item"><b>Координаты:</b> ${format(coordinates[0], 6)}, ${format(coordinates[1], 6)}</div>`)
            } else if (popup[prop] === 'cloud') {
              description.push(`<div class="popupPower__button"><a target=_blank href="/cloud/${properties[prop]}">Облако точек</a></div>`)
            } else {
              const propValue = properties[prop] == null || properties[prop] === ''
                ? '-'
                : properties[prop]
              description.push(index
                ? `<div class="popupPower__item"><b>${popup[prop]}:</b> ${propValue ?? '-'}</div>`
                : `<div class="popupPower__title">
                    ${popup[prop]}${propValue ?? '-'}
                </div>`
              )
            }
          })
          if (options.click === 'zoom') {
            setTimeout(() => {
              mapParams.popupClick
                .setLngLat(coordinates)
                .setHTML(description.join('\n'))
                .addTo(map.value)
            }, 1500)
          } else {
            mapParams.popupClick
              .setLngLat(e.lngLat)
              .setHTML(description.join('\n'))
              .addTo(map.value)
          }
        }
      }
      const clickCluster = (features) => {
        map.value.getSource(features[0].layer.source).getClusterExpansionZoom(
          features[0].properties.cluster_id,
          function (err, zoom) {
            if (err) return

            map.value.easeTo({
              center: features[0].geometry.coordinates,
              zoom: zoom
            })
          }
        )
      }
      const mousemoveFeature = (e, { popup, hover } = {}) => {
        if (drawMode.value) {
          return
        }
        map.value.getCanvas().style.cursor = 'pointer'
        if (hover === 'popup') {
          const format = (val, n, s = 0) => Math.round(val * 10 ** n) / (10 ** n) / (10 ** s)
          if (e.features.length > 0 && !mapParams.popupClick.isOpen()) {
            const features = e.features.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i)
            const description = []
            if (!!Object.keys(popup).length || !popup) {
              features.forEach(({
                geometry,
                properties
              }) => {
                Object.keys(popup).forEach((prop, index) => {
                  if (popup[prop] === 'cloud') {
                    return
                  }
                  if (prop === 'coordinates') {
                    const coordinates = geometry.type === 'Point'
                      ? geometry.coordinates
                      : centroid(e.features[0]).geometry.coordinates
                    description.push(`<div class="popupPower__item"><b>Координаты:</b> ${format(coordinates[0], 6)}, ${format(coordinates[1], 6)}</div>`)
                  } else {
                    const propValue = properties[prop] == null || properties[prop] === ''
                      ? '-'
                      : properties[prop]
                    description.push(index
                      ? `<div class="popupPower__item"><b>${popup[prop]}:</b> ${propValue ?? '-'}</div>`
                      : `<div class="popupPower__title">
                      ${popup[prop]}${propValue ?? '-'}
                  </div>`
                    )
                  }
                })
              })
              mapParams.popup
                .setLngLat(e.lngLat)
                .setHTML(description.join('\n'))
                .addTo(map.value)
            }
          }
        }

        if (mapParams.hover.id !== null) {
          map.value.setFeatureState({ ...mapParams.hover }, { hover: false })
        }
        mapParams.hover = {
          id: e.features[0].id,
          sourceLayer: e.features[0].sourceLayer ?? null,
          source: e.features[0].layer.source
        }
        map.value.setFeatureState(
          {
            source: e.features[0].layer.source,
            sourceLayer: e.features[0].sourceLayer ?? null,
            id: e.features[0].id
          },
          { hover: true }
        )
      }
      const mouseleaveFeature = () => {
        if (drawMode.value) {
          return
        }
        map.value.getCanvas().style.cursor = ''
        if (mapParams.hover.id !== null) {
          map.value.setFeatureState(
            { ...mapParams.hover },
            { hover: false }
          )
        }
        mapParams.hover.id = null
        if (mapParams.popup) {
          mapParams.popup.remove()
        }
      }
      // eslint-disable-next-line no-unused-expressions
      const onMap = async () => {
        await addImages(map.value, [
          { id: 'foliage', url: foliage },
          { id: 'graveyard', url: graveyard },
          { id: 'waterdrain', url: waterdrain },
          { id: 'burialground', url: burialground },
          { id: 'okn', url: okn },
          { id: 'fish', url: fish }
        ])
        await addIcons(map.value, [
          { id: 'square', sizeX: 20, sizeY: 20, color: '#000000' },
          { id: 'line', sizeX: 12, sizeY: 1, color: '#000000' },
          { id: 'line-short', sizeX: 1, sizeY: 3, color: '#000000' }
        ])
        mapParams.popup = new mapboxgl.Popup({
          closeButton: false,
          className: 'popupPower',
          maxWidth: 240,
          offset: 10
        })
        mapParams.popupClick = new mapboxgl.Popup({
          closeButton: true,
          className: 'popupPower popupPower--click',
          maxWidth: 240,
          offset: 10
        })
        const clickLayers = []
        await store.dispatch(SOURCES_GET)
        const sources = store.getters.getSources
        await Object.keys(sources).forEach(nameSource => {
          map.value.addSource(nameSource, sources[nameSource].options)
          if (sources[nameSource].filter) {
            layersFilter[nameSource] = []
          }
          for (const layer of sources[nameSource].layers) {
            map.value.addLayer(layer)
            if (sources[nameSource].filter) {
              layersFilter.value.push({
                id: layer.id,
                filter: layer.filter || false
              })
            }
            const options = layer.options ?? { hover: false, click: false, popup: {} }
            const hover = options.hover ?? false
            const click = options.click ?? false
            const popup = options.popup ?? {}
            if (hover) {
              map.value.on('mousemove', layer.id, e => mousemoveFeature(e, { hover, popup }))
              map.value.on('mouseleave', layer.id, () => mouseleaveFeature())
            }
            if (click) {
              clickLayers.unshift({
                click,
                layerId: layer.id,
                sourceLayer: layer.sourceLayer ?? null,
                popup
              })
            }
          }
        })
        dataLoading.value = true
        map.value.on('click', e => {
          if (drawMode.value) {
            return
          }
          let features = []
          if (mapParams.click.id !== null) {
            map.value.setFeatureState(
              { ...mapParams.click },
              { clicked: false }
            )
            mapParams.click = {
              id: null,
              source: null,
              sourceLayer: null
            }
          }
          for (const item of clickLayers) {
            features = map.value.queryRenderedFeatures(e.point, { layers: [item.layerId] })
            features = features.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i)
            if (features.length) {
              if (item.click === 'cluster') {
                clickCluster(features)
              } else {
                clickOnObject(e, features[0], { ...item })
              }
              break
            }
          }
        })
      }
      onMap()
    })
  })
  const drawModeOn = () => {
    drawMode.value = true
  }
  const drawModeOff = () => {
    drawMode.value = false
  }
  provide('map', map)
  provide('drawMode', drawMode)
  provide('drawModeOn', drawModeOn)
  provide('drawModeOff', drawModeOff)
  return {
    map,
    mapInit,
    dataLoading,
    layersFilter,
    featureActive
  }
}

function addImages (map, images) {
  const addImage = (map, id, url) => {
    return new Promise((resolve, reject) => {
      map.loadImage(url, (error, image) => {
        if (error) {
          reject(error)
          return
        }
        map.addImage(id, image)
        resolve(image)
      })
    })
  }
  const promises = images.map(imageData => addImage(map, imageData.id, imageData.url))
  return Promise.all(promises)
}

function addIcons (map, icons) { // id, rgba = [255, 138, 0, 255]
  const bytesPerPixel = 4
  const hexToRGB = color => {
    color = color.substring(0, 1) === '#' ? color.substring(1) : color
    return {
      r: parseInt(color.substring(0, 2), 16),
      g: parseInt(color.substring(2, 4), 16),
      b: parseInt(color.substring(4), 16)
    }
  }
  icons.forEach(icon => {
    const { id, sizeX, sizeY, color } = icon
    const data = new Uint8Array(sizeX * sizeY * bytesPerPixel)
    const rgb = hexToRGB(color)
    for (let x = 0; x < sizeX; x++) {
      for (let y = 0; y < sizeY; y++) {
        const offset = (y * sizeX + x) * bytesPerPixel
        data[offset + 0] = rgb.r
        data[offset + 1] = rgb.g
        data[offset + 2] = rgb.b
        data[offset + 3] = 255
      }
    }
    map.addImage(id, { width: sizeX, height: sizeY, data: data })
  })
}
