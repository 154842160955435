export const getBackLayers = () => {
  return {
    satellite: {
      options: {
        type: 'raster',
        tiles: ['https://mt0.google.com/vt/lyrs=y&hl=ru&x={x}&y={y}&z={z}'],
        tileSize: 256
      },
      layers: [
        {
          id: 'satellite',
          type: 'raster',
          source: null,
          layout: {
            visibility: 'none'
          }
        }
      ]
    },
    dem: {
      options: {
        type: 'raster',
        tiles: [`${window.location.origin}/data/common/tiles/kem_dem_z18/{z}/{x}/{y}.png`],
        tileSize: 256,
        bounds: [84.4480556, 52.1629167, 89.4025, 56.8343056],
        maxzoom: 18
      },
      layers: [
        {
          id: 'dem',
          type: 'raster',
          source: null,
          layout: {
            visibility: 'none'
          }
        }
      ]
    },
    kem_hillshade: {
      options: {
        type: 'raster',
        tiles: [`${window.location.origin}/data/common/tiles/kem_hillshade_z16/{z}/{x}/{y}.png`],
        tileSize: 256,
        bounds: [84.4480556, 52.1629167, 89.4025, 56.8343056],
        maxzoom: 16
      },
      layers: [
        {
          id: 'kem_hillshade',
          type: 'raster',
          source: null,
          layout: {
            visibility: 'none'
          }
        }
      ]
    },
    forest: {
      options: {
        type: 'raster',
        tiles: [`${window.location.origin}/data/common/tiles/kem_chm_forest_2017_z14/{z}/{x}/{y}.png`],
        tileSize: 256,
        bounds: [84.44806, 52.16278, 89.4025, 56.83417],
        maxzoom: 14
      },
      layers: [
        {
          id: 'forest',
          type: 'raster',
          source: null,
          layout: {
            visibility: 'none'
          }
        }
      ]
    }
  }
}
