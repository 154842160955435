import { getOrthoLayers } from '@/js/map/_layers/ortho'
import { getBackLayers } from '@/js/map/_layers/back'
import { getTopo10Layer } from '@/js/map/_layers/topo10'
import { getTopo25Layer } from '@/js/map/_layers/topo25'
import { getFloodLayer } from '@/js/map/_layers/flood'
import { getTopoKrasnLayer } from '@/js/map/_layers/krasnoznamenka'
import { getTopoOsinPlesoLayer } from '@/js/map/_layers/osin_pleso'

import markerOkn from '@/assets/marker_okn.png'
import waterdrain from '@/assets/marker_water.png'
import burialground from '@/assets/marker_burialground.png'
import fish from '@/assets/marker_fish.png'

import { getGesTopoLayer } from './_layers/ges'

export async function layersList () {
  const preSources = {
    zu: {
      legend: {
        name: 'Земельные участки',
        color: '#FB4141',
        unitedLayer: {
          id: 'egrn',
          name: 'Объекты недвижимости'
        }
      },
      visible: 'none',
      options: {
        type: 'vector',
        tiles: [`${window.location.origin}/data/vector/zu-z20/{z}/{x}/{y}.pbf`],
        minzoom: 0,
        maxzoom: 20,
        bounds: [-41.314319, 46.499605, 88.101009, 69.456855]
      },
      layers: [
        {
          id: 'zu_fill',
          type: 'fill',
          source: null,
          'source-layer': 'zu',
          paint: {
            'fill-color': [
              'case',
              ['boolean',
                ['feature-state', 'clicked'],
                false
              ],
              '#FFFA7E',
              '#FB4141'
            ],
            'fill-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 0.5, 0.3]
          },
          options: {
            hover: true,
            click: 'popup',
            popup: {
              Fed_KN: 'Кадастровый номер ',
              FiasAddrs: 'Адрес',
              Category: 'Категория',
              SubTypeVal: 'Вид объекта',
              ByDoc: 'Вид использования по документу',
              CadCost: 'Кадастровая стоимость, руб.',
              AreaValue: 'Площадь, кв.м'
            } // first prop - title popup
          }
        },
        {
          id: 'zu_line',
          type: 'line',
          source: null,
          'source-layer': 'zu',
          paint: {
            'line-color': '#FB4141',
            'line-width': 1
          }
        }
      ]
    },
    oks: {
      legend: {
        name: 'Объекты капитального строительства',
        color: '#00AB59',
        unitedLayer: {
          id: 'egrn',
          name: 'Объекты недвижимости'
        }
      },
      visible: 'none',
      options: {
        type: 'vector',
        tiles: [`${window.location.origin}/data/vector/oks-z20/{z}/{x}/{y}.pbf`],
        minzoom: 0,
        maxzoom: 20,
        bounds: [86.833124, 53.950894, 87.821265, 55.009199]
      },
      layers: [
        {
          id: 'oks_fill',
          type: 'fill',
          source: null,
          'source-layer': 'oks',
          paint: {
            'fill-color': [
              'case',
              ['boolean',
                ['feature-state', 'clicked'],
                false
              ],
              '#FFFA7E',
              '#00AB59'
            ],
            'fill-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 0.9, 0.6]
          },
          options: {
            hover: true,
            click: 'popup',
            popup: {
              Fed_KN: 'Кадастровый номер ',
              ComDataVal: 'Тип объекта',
              FiasAddrs: 'Адрес',
              CadCost: 'Кадастровая стоимость, руб.'
            } // first prop - title popup
          }
        },
        {
          id: 'oks_line',
          type: 'line',
          source: null,
          'source-layer': 'oks',
          paint: {
            'line-color': '#00AB59',
            'line-width': 1
          }
        }
      ]
    },
    nedra: {
      legend: {
        name: 'Недропользование',
        color: '#390099'
      },
      visible: 'none',
      options: {
        type: 'geojson',
        data: './shapes/nedra.geojson',
        generateId: true
      },
      layers: [
        {
          id: 'nedra_fill',
          type: 'fill',
          source: null,
          paint: {
            'fill-color': [
              'case',
              ['boolean',
                ['feature-state', 'clicked'],
                false
              ],
              '#FFFA7E',
              '#390099'
            ],
            'fill-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 0.7, 0.5]
          },
          options: {
            hover: true,
            click: 'popup',
            popup: {
              lic: 'Лицензия № ',
              name: 'Участок',
              owner: 'Организация',
              activity: 'Деятельность',
              enddate: 'Окончание лицензии'
            } // first prop - title popup
          }
        },
        {
          id: 'nedra_line',
          type: 'line',
          source: null,
          paint: {
            'line-color': '#390099',
            'line-width': 1
          }
        }
      ]
    },
    oopt: {
      legend: {
        name: 'Особо охраняемые природные территории (ООПТ)',
        color: '#9ED726',
        unitedLayer: {
          id: 'oopt',
          name: 'Особо охраняемые природные территории (ООПТ)'
        }
      },
      options: {
        type: 'geojson',
        data: './shapes/oopt.geojson',
        generateId: true
      },
      layers: [
        {
          id: 'oopt_fill',
          type: 'fill',
          source: null,
          paint: {
            'fill-color': [
              'case',
              ['boolean',
                ['feature-state', 'clicked'],
                false
              ],
              '#FFFA7E',
              '#9ED726'
            ],
            'fill-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 0.7, 0.5]
          },
          options: {
            hover: true,
            click: 'popup',
            popup: {
              name: '',
              s_ga: 'Площадь, га'
            } // first prop - title popup
          }
        },
        {
          id: 'oopt_line',
          type: 'line',
          source: null,
          paint: {
            'line-color': '#9ED726',
            'line-width': 1
          }
        }
      ]
    },
    oopt_new: {
      legend: {
        name: 'Вновь образуемые участки замещения ООПТ',
        color: '#00E4D6',
        unitedLayer: {
          id: 'oopt',
          name: 'Особо охраняемые природные территории (ООПТ)'
        }
      },
      options: {
        type: 'geojson',
        data: './shapes/oopt_new.geojson',
        generateId: true
      },
      layers: [
        {
          id: 'oopt_new_fill',
          type: 'fill',
          source: null,
          filter: ['==', 'flood_h', 175],
          paint: {
            'fill-color': [
              'case',
              ['boolean',
                ['feature-state', 'clicked'],
                false
              ],
              '#FFFA7E',
              '#00E4D6'
            ],
            'fill-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 0.7, 0.5]
          },
          options: {
            hover: true,
            click: 'popup',
            popup: {
              name: '',
              s_ga: 'Площадь, га'
            } // first prop - title popup
          }
        },
        {
          id: 'oopt_new_line',
          type: 'line',
          source: null,
          filter: ['==', 'flood_h', 175],
          paint: {
            'line-color': '#00E4D6',
            'line-width': 1
          }
        }
      ]
    },
    fish_polygon: {
      legend: {
        name: 'Нерестилища рыб',
        color: '#DA472A',
        unitedLayer: {
          id: 'fish',
          name: 'Ихтиофауна'
        }
      },
      visible: 'none',
      options: {
        type: 'geojson',
        data: './shapes/fish_polygon.geojson',
        generateId: true
      },
      layers: [
        {
          id: 'fish_polygon_fill',
          type: 'fill',
          source: null,
          paint: {
            'fill-color': [
              'case',
              ['boolean',
                ['feature-state', 'clicked'],
                false
              ],
              '#FFFA7E',
              '#DA472A'
            ],
            'fill-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 0.7, 0.5]
          },
          options: {
            hover: true,
            click: 'popup',
            popup: {
              id: 'Нерестилище рыб #',
              fish: 'Виды рыб'
            } // first prop - title popup
          }
        },
        {
          id: 'fish_polygon_line',
          type: 'line',
          source: null,
          paint: {
            'line-color': '#DA472A',
            'line-width': 1
          }
        }
      ]
    },
    fish_winterpit: {
      legend: {
        name: 'Зимовальные ямы',
        color: 'none',
        icon: fish,
        unitedLayer: {
          id: 'fish',
          name: 'Ихтиофауна'
        }
      },
      visible: 'none',
      options: {
        type: 'geojson',
        data: './shapes/fish_winterpit.geojson',
        generateId: true
      },
      layers: [
        {
          id: 'winterpit',
          type: 'circle',
          source: null,
          paint: {
            'circle-color': '#fff',
            'circle-radius': [
              'interpolate',
              ['linear'],
              ['zoom'],
              10, 14,
              18, 20
            ],
            'circle-stroke-width': 2,
            'circle-stroke-color': ['case', ['boolean', ['feature-state', 'hover'], false], '#DA472A', 'transparent']
          },
          options: {
            hover: 'popup',
            click: 'zoom',
            popup: {
              id: 'Зимовальная яма #',
              coordinates: 'Координаты'
            } // first prop - title popup
          }
        },
        {
          id: 'winterpit_icons',
          type: 'symbol',
          source: null,
          layout: {
            'icon-allow-overlap': true,
            'icon-image': 'fish',
            'icon-size': [
              'interpolate',
              ['linear'],
              ['zoom'],
              10, 0.2,
              18, 0.4
            ]
          }
        }
      ]
    },
    okn_polygon: {
      legend: {
        name: 'Участки ОКН',
        color: '#FF7D34',
        unitedLayer: {
          id: 'okn',
          name: 'Объекты культурного наследия (ОКН)'
        }
      },
      options: {
        type: 'geojson',
        data: './shapes/okn_polygon.geojson',
        generateId: true
      },
      layers: [
        {
          id: 'okn_polygon_fill',
          type: 'fill',
          source: null,
          paint: {
            'fill-color': [
              'case',
              ['boolean',
                ['feature-state', 'clicked'],
                false
              ],
              '#FFFA7E',
              '#FF7D34'
            ],
            'fill-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 0.7, 0.3]
          },
          minzoom: 13,
          options: {
            hover: true,
            click: 'popup',
            popup: {
              name: '',
              coordinates: 'Координаты',
              id: 'cloud'
            } // first prop - title popup
          }
        },
        {
          id: 'okn_polygon_line',
          type: 'line',
          source: null,
          minzoom: 13,
          paint: {
            'line-color': '#FF7D34',
            'line-width': 1
          }
        }
      ]
    },
    okn_points: {
      legend: {
        name: 'Местоположение ОКН',
        color: '#fff',
        icon: markerOkn,
        unitedLayer: {
          id: 'okn',
          name: 'Объекты культурного наследия'
        }
      },
      options: {
        type: 'geojson',
        data: './shapes/okn_point.geojson',
        generateId: true,
        cluster: true,
        clusterMaxZoom: 13,
        clusterRadius: 30
      },
      layers: [
        {
          id: 'okn_clusters',
          type: 'circle',
          source: null,
          options: {
            hover: true,
            click: 'cluster'
          },
          filter: ['has', 'point_count'],
          paint: {
            'circle-color': '#FF7D34',
            'circle-radius': 16,
            'circle-stroke-width': 3,
            'circle-stroke-color': ['case', ['boolean', ['feature-state', 'hover'], false], '#ffffff', 'transparent']
          }
        }, {
          id: 'okn_clusters-count',
          type: 'symbol',
          source: null,
          filter: ['has', 'point_count'],
          layout: {
            'text-field': '{point_count_abbreviated}',
            'text-font': ['Open Sans Bold'],
            'text-size': 12
          },
          paint: {
            'text-color': '#fff',
            'text-halo-color': 'rgba(50,50,50,0.2)',
            'text-halo-width': 1
          }
        },
        {
          id: 'okn_points',
          type: 'circle',
          source: null,
          filter: ['!', ['has', 'point_count']],
          paint: {
            'circle-color': '#fff',
            'circle-radius': [
              'interpolate',
              ['linear'],
              ['zoom'],
              10, 14,
              18, 20
            ],
            'circle-stroke-width': 2,
            'circle-stroke-color': ['case', ['boolean', ['feature-state', 'hover'], false], '#FF7D34', 'transparent']
          },
          maxzoom: 15,
          options: {
            hover: 'popup',
            click: 'zoom',
            popup: {
              name: '',
              coordinates: 'Координаты',
              id: 'cloud'
            } // first prop - title popup
          }
        },
        {
          id: 'okn_points_icons',
          type: 'symbol',
          source: null,
          filter: ['!', ['has', 'point_count']],
          paint: {
            'text-color': '#fff'
          },
          layout: {
            'icon-allow-overlap': true,
            'icon-image': 'okn',
            'icon-size': [
              'interpolate',
              ['linear'],
              ['zoom'],
              10, 0.2,
              18, 0.4
            ]
          },
          maxzoom: 15
        }
      ]
    },
    waterdrain: {
      legend: {
        name: 'Точки отбора проб воды',
        color: '#00A0E4',
        icon: waterdrain
      },
      options: {
        type: 'geojson',
        data: './shapes/waterdrain_point.geojson',
        generateId: true,
        cluster: true,
        clusterMaxZoom: 13,
        clusterRadius: 30
      },
      layers: [
        {
          id: 'waterdrain_clusters',
          type: 'circle',
          source: null,
          options: {
            hover: true,
            click: 'cluster'
          },
          filter: ['has', 'point_count'],
          paint: {
            'circle-color': '#00A0E4',
            'circle-radius': 16,
            'circle-stroke-width': 3,
            'circle-stroke-color': ['case', ['boolean', ['feature-state', 'hover'], false], '#ffffff', 'transparent']
          }
        }, {
          id: 'waterdrain_clusters-count',
          type: 'symbol',
          source: null,
          filter: ['has', 'point_count'],
          layout: {
            'text-field': '{point_count_abbreviated}',
            'text-font': ['Open Sans Bold'],
            'text-size': 12
          },
          paint: {
            'text-color': '#fff',
            'text-halo-color': 'rgba(50,50,50,0.2)',
            'text-halo-width': 1
          }
        },
        {
          id: 'waterdrain_points',
          type: 'circle',
          source: null,
          filter: ['!', ['has', 'point_count']],
          paint: {
            'circle-color': '#fff',
            'circle-radius': [
              'interpolate',
              ['linear'],
              ['zoom'],
              10, 14,
              18, 20
            ],
            'circle-stroke-width': 2,
            'circle-stroke-color': ['case', ['boolean', ['feature-state', 'hover'], false], '#00A0E4', 'transparent']
          },
          options: {
            hover: 'popup',
            click: 'zoom',
            popup: {
              text: '',
              id: 'Уникальный идентификатор',
              coordinates: 'Координаты'
            } // first prop - title popup
          }
        },
        {
          id: 'waterdrain_icons',
          type: 'symbol',
          source: null,
          filter: ['!', ['has', 'point_count']],
          paint: {
            'text-color': '#fff'
          },
          layout: {
            'icon-allow-overlap': true,
            'icon-image': 'waterdrain',
            'icon-size': [
              'interpolate',
              ['linear'],
              ['zoom'],
              10, 0.2,
              18, 0.4
            ]
          }
        }
      ]
    },
    burial: {
      legend: {
        name: 'Местоположение',
        color: 'none',
        icon: burialground,
        unitedLayer: {
          id: 'topo_skot',
          name: 'Скотомогильники'
        }
      },
      options: {
        type: 'geojson',
        data: './shapes/burialground.geojson',
        generateId: true
      },
      layers: [
        {
          id: 'burialground',
          type: 'circle',
          source: null,
          paint: {
            'circle-color': '#fff',
            'circle-radius': [
              'interpolate',
              ['linear'],
              ['zoom'],
              10, 14,
              18, 20
            ],
            'circle-stroke-width': 2,
            'circle-stroke-color': ['case', ['boolean', ['feature-state', 'hover'], false], '#14595B', 'transparent']
          },
          options: {
            hover: 'popup',
            click: 'zoom',
            popup: {
              text: '',
              coordinates: 'Координаты',
              id: 'cloud'
            } // first prop - title popup
          }
        },
        {
          id: 'burialground_icons',
          type: 'symbol',
          source: null,
          layout: {
            'icon-allow-overlap': true,
            'icon-image': 'burialground',
            'icon-size': [
              'interpolate',
              ['linear'],
              ['zoom'],
              10, 0.2,
              18, 0.4
            ]
          }
        }
      ]
    }
  }

  const sources = Object.assign(
    getBackLayers(),
    await getOrthoLayers(),
    getFloodLayer(),
    getGesTopoLayer(),
    getTopo10Layer(),
    getTopo25Layer(),
    getTopoKrasnLayer(),
    getTopoOsinPlesoLayer(),
    preSources
  )

  for (const nameSource in sources) {
    sources[nameSource].layers.map(layer => {
      layer.source = nameSource
      return layer
    })
    if (sources[nameSource].visible === 'none') {
      for (const i in sources[nameSource].layers) {
        if ('layout' in sources[nameSource].layers[i]) {
          // eslint-disable-next-line
          sources[nameSource].layers[i].layout['visibility'] = 'none'
        } else {
          // eslint-disable-next-line
          sources[nameSource].layers[i]['layout'] = { visibility: 'none' }
        }
      }
    }
  }
  return sources
}

const getVisibleLayer = layers => {
  const visibleLayers = layers.filter(layer => {
    if ('layout' in layer && 'visibility' in layer.layout) {
      return layer.layout.visibility !== 'none'
    } else {
      return true
    }
  })
  return !!visibleLayers.length
}

export const getLegendLayers = sources => {
  const legendLayers = []
  for (const nameSource in sources) {
    if (sources[nameSource].legend) {
      let indexUnitedLayer = null

      if (sources[nameSource].legend.unitedLayer) {
        const unitedLayer = sources[nameSource].legend.unitedLayer
        indexUnitedLayer = legendLayers.findIndex(layer => layer.id === unitedLayer.id)

        if (indexUnitedLayer === -1) {
          legendLayers.push({
            id: unitedLayer.id,
            name: unitedLayer.name,
            visible: null,
            open: false,
            list: []
          })
          indexUnitedLayer = legendLayers.findIndex(layer => layer.id === unitedLayer.id)
        }
      }
      const color = (sources[nameSource].legend.color)
        ? sources[nameSource].legend.color === 'none'
          ? 'transparent'
          : sources[nameSource].legend.color
        : sources[nameSource].layers.find(layer => layer.type === 'fill').paint['fill-color']
      const newLegendLayer = {
        id: nameSource,
        name: sources[nameSource].legend.name,
        layers: sources[nameSource].layers.map(layer => layer.id),
        visible: getVisibleLayer(sources[nameSource].layers),
        color,
        icon: sources[nameSource].legend.icon || false,
        signatures: sources[nameSource].legend.signatures || []
      }
      if (legendLayers[indexUnitedLayer]) {
        legendLayers[indexUnitedLayer].list.push(newLegendLayer)
      } else {
        legendLayers.push(newLegendLayer)
      }
    }
  }
  // видимость объедененных слоев
  legendLayers.forEach((layer, index) => {
    if (!('layers' in layer)) {
      legendLayers[index].visible = !!layer.list.filter(sublayer => sublayer.visible).length
    }
  })
  return legendLayers
}
