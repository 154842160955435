export const getTopoKrasnLayer = () => {
  return {
    topoKrasn: {
      legend: {
        name: 'Топоплан c.Краснознаменка (M 1:500)',
        color: 'none',
        signatures: [
          { name: 'Граница воздействия на объекты гидрографии', color: '#00ffff', type: 'dashline' },
          { name: 'Граница воздействия на жилые объекты', color: '#db1e2a', type: 'dashline' },
          { name: 'Забор биотермической ямы', color: '#DB8C16', type: 'line' },
          { name: 'Граница выделенного земельного участка', color: '#f00', type: 'line' }
        ],
        unitedLayer: {
          id: 'topo_skot',
          name: 'Скотомогильники'
        }
      },
      visible: 'none',
      options: {
        type: 'vector',
        tiles: [`${window.location.origin}/data/vector/cattle/Краснознаменка-z20/{z}/{x}/{y}.pbf`],
        minzoom: 0,
        maxzoom: 20,
        bounds: [87.504851, 54.122833, 87.537833, 54.142091]
      },
      layers: [
        {
          id: 'kr_horizontal',
          type: 'line',
          source: null,
          'source-layer': 'horizontal',
          paint: {
            'line-color': '#a55200',
            'line-width': [
              'match',
              ['get', 'layer'],
              '1',
              1,
              '2',
              2,
              /* other */ 1
            ]
          }
        },
        {
          id: 'kr_water_polygon',
          type: 'fill',
          source: null,
          'source-layer': 'water_polygon',
          paint: {
            'fill-color': '#00ffff',
            'fill-opacity': 0.7
          }
        },
        {
          id: 'kr_horizontal_text',
          type: 'symbol',
          source: null,
          'source-layer': 'horizontal',
          paint: {
            'text-color': '#000',
            'text-halo-color': '#fff',
            'text-halo-width': 1
          },
          layout: {
            'symbol-placement': 'line',
            'text-field': '{h}',
            'text-size': 12,
            'text-anchor': 'bottom'
          }
        },
        {
          id: 'kr_h_point_circle',
          type: 'circle',
          source: null,
          'source-layer': 'h_point',
          paint: {
            'circle-radius': 4,
            'circle-color': '#000000'
          },
          layout: {
            'circle-sort-key': 100
          },
          minzoom: 17
        },
        {
          id: 'kr_h_point_text',
          type: 'symbol',
          source: null,
          'source-layer': 'h_point',
          layout: {
            'text-field': '{h}',
            'text-size': 12,
            'text-variable-anchor': ['top-left', 'top-right', 'bottom-left', 'bottom-right'],
            'text-radial-offset': 0.3,
            'text-ignore-placement': true,
            'text-allow-overlap': false
          },
          paint: {
            'text-halo-color': '#fff',
            'text-halo-width': 1
          },
          minzoom: 17
        },
        {
          id: 'kr_contour',
          type: 'line',
          source: null,
          'source-layer': 'contour',
          paint: {
            'line-color': [
              'match',
              ['get', 'layer'],
              'Внутренний',
              '#00ffff',
              'Внешний',
              '#db1e2a',
              /* other */ '#db1e2a'
            ],
            'line-width': 2,
            'line-dasharray': [8, 2]
          }
        },
        {
          id: 'kr_water_polygon_line',
          type: 'line',
          source: null,
          'source-layer': 'water_polygon',
          paint: {
            'line-color': '#000000',
            'line-width': 0.5
          }
        },
        {
          id: 'kr_flood_line',
          type: 'line',
          source: null,
          'source-layer': 'flood_line_1_500',
          paint: {
            'line-color': [
              'match',
              ['get', 'layer'],
              '177.5',
              '#ff0000',
              '175',
              '#0000ff',
              /* other */ '#0000ff'
            ],
            'line-width': 1
          }
        },
        {
          id: 'kr_road_line',
          type: 'line',
          source: null,
          'source-layer': 'road_line',
          paint: {
            'line-color': '#000000',
            'line-width': [
              'match',
              ['get', 'layer'],
              'С покрытием',
              3,
              'Грунтовые',
              1,
              /* other */ 1
            ]
          }
        },
        {
          id: 'kr_road_point',
          type: 'symbol',
          source: null,
          'source-layer': 'road_point',
          paint: {
            'text-halo-color': 'rgba(255, 255, 255, 0.5)',
            'text-halo-width': 1
          },
          layout: {
            'text-field': '{text}',
            'text-anchor': 'center',
            'text-size': 12
          }
        },
        {
          id: 'kr_building_polygon',
          type: 'fill',
          source: null,
          'source-layer': 'building_polygon',
          paint: {
            'fill-color': '#000000'
          }
        },
        {
          id: 'kr_boundary',
          type: 'line',
          source: null,
          'source-layer': 'boundary',
          paint: {
            'line-color': [
              'match',
              ['get', 'layer'],
              'Граница участка',
              '#f00',
              'Забор',
              '#DB8C16',
              /* other */ '#000'
            ],
            'line-width': 2
          }
        },
        {
          id: 'kr_quarter_line',
          type: 'line',
          source: null,
          'source-layer': 'quarter_line',
          paint: {
            'line-color': '#000000',
            'line-width': 1
          }
        }
      ]
    }
  }
}
