<template lang="pug">
Measure(v-if="measure && !route" :measure="measure")
Route(v-if="route" @route-close="routeToggle")
.map__control.mapControl
  Layers
  .mapControl__groupe.mapControl__groupe--drop
    .mapControl__item.mapControl__item--text.mapControl__item--drop(
      :class="{ active: layers.open }"
      @click="toggleListLayers"
      v-click-away="hideListLayers"
    )
      | {{ layers.list[layers.active].name }}
      svg(width="14" height="8" viewBox="0 0 14 8")
        path(fill-rule="evenodd" clip-rule="evenodd" d="M5.76262 1.17672L0.469727 6.46961L1.53039 7.53027L6.82328 2.23738C6.92091 2.13975 7.0792 2.13975 7.17683 2.23738L12.4697 7.53027L13.5304 6.46961L8.23749 1.17672C7.55408 0.493302 6.44604 0.493302 5.76262 1.17672Z")
    .mapControlDrop(v-if="layers.open")
      .mapControlDrop__item(
        v-for="(layer, index) in layers.list"
        :key="index"
        :class="{ active: index==layers.active }"
        @click="[selectLayer(index)]"
      ) {{ layer.name }}
  .mapControl__groupe
    .mapControl__item(title="Приблизить" @click="zoomIn")
      .mapControl__icon.mapControl__icon--zoomIn
    .mapControl__item(title="Отдалить" @click="zoomOut")
      .mapControl__icon.mapControl__icon--zoomOut
    .mapControl__item(title="Повернуть карту на север" @click="bearingSouth")
      .mapControl__icon.mapControl__icon--orient
  .mapControl__groupe
    .mapControl__item(title="Измерить расстояние" @click="[ drawLine(), routeOff() ]")
      .mapControl__icon.mapControl__icon--distance
    .mapControl__item(title="Измерить площадь" @click="[ drawPoly(), routeOff() ]")
      .mapControl__icon.mapControl__icon--square
    .mapControl__item(title="Координаты" @click="[ coordPoint(), routeOff() ]")
      .mapControl__icon.mapControl__icon--coord
    .mapControl__item(title="Удалить измерение" @click="[ trash(), routeOff() ]")
      .mapControl__icon.mapControl__icon--remove
    .mapControl__item(title="Маршрут" @click="routeToggle()" :class="{ active: route }")
      .mapControl__icon.mapControl__icon--route
    .mapControl__item(title="Напечатать" @click="print")
      .mapControl__icon.mapControl__icon--print
.reliefInfo(v-if="visibleInfo==='relief'")
  .reliefInfo__close(@click="visibleInfo=false")
    svg(width="12" height="12" viewBox="0 0 12 12" fill="none")
      path(fill-rule="evenodd" clip-rule="evenodd" d="M10 11.0607L0 1.06066L1.06066 0L11.0607 10L10 11.0607Z")
      path(fill-rule="evenodd" clip-rule="evenodd" d="M1.06077 11.0607L11.0608 1.06066L10.0001 0L0.000113452 10L1.06077 11.0607Z")
  .reliefInfo__title Цифровая модель рельефа
  .reliefInfo__field
    b Поставщик:
    | USGS
  .reliefInfo__field
    b Название сенсора:
    | Aster Global DEM
  .reliefInfo__field
    b Спутник:
    | Terra
  .reliefInfo__field
    b Разрешение:
    | 30x30 м
  .reliefInfo__height
    b Высота над уровнем моря, м
    .reliefInfo__gradient
    ul
      li 75
      li 1100
.reliefInfo(v-if="visibleInfo==='forest'")
  .reliefInfo__close(@click="visibleInfo=false")
    svg(width="12" height="12" viewBox="0 0 12 12" fill="none")
      path(fill-rule="evenodd" clip-rule="evenodd" d="M10 11.0607L0 1.06066L1.06066 0L11.0607 10L10 11.0607Z")
      path(fill-rule="evenodd" clip-rule="evenodd" d="M1.06077 11.0607L11.0608 1.06066L10.0001 0L0.000113452 10L1.06077 11.0607Z")
  .reliefInfo__title Высотная модель лесного массива
  .reliefInfo__field
    b Источник:
    | Результат разности цифровых моделей поверхности (ALOS World 3D) и рельефа (ASTER GDEM)
  .reliefInfo__field
    b Разрешение:
    | 30x30 м
  .reliefInfo__height
    b Высота лесного массива, м
    .reliefInfo__gradient.reliefInfo__gradient--forest
    ul
      li 5
      li 31
</template>

<script>
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import Measure from '@/components/Measure'
import { useControl } from '@/js/use/control'
import Route from '@/components/Route'
import Layers from '@/components/Layers'

export default {
  components: {
    Route,
    Measure,
    Layers
  },
  setup () {
    return {
      ...useControl()
    }
  }
}
</script>
