export const getFloodLayer = () => {
  return {
    flood: {
      legend: {
        name: 'Затопление',
        signatures: [
          { name: 'Береговая линия (20м)', color: '#00f', type: 'line' },
          { name: 'Водохранная зона (200м)', color: '#C6B419', type: 'line' }
        ],
        unitedLayer: {
          id: 'flood',
          name: 'Затопление'
        }
      },
      options: {
        type: 'vector',
        tiles: [`${window.location.origin}/data/vector/flood_2022-03-z20/{z}/{x}/{y}.pbf`],
        minzoom: 0,
        maxzoom: 20,
        bounds: [86.958435, 54.037225, 87.621615, 55.168060]
      },
      layers: [
        {
          id: 'flood_20m',
          type: 'line',
          source: null,
          'source-layer': '20m',
          filter: ['==', 'layer', '175'],
          paint: {
            'line-color': '#00f',
            'line-width': 1,
            'line-opacity': 0.7
          }
        },
        {
          id: 'flood_200m',
          type: 'line',
          source: null,
          'source-layer': '200m',
          filter: ['==', 'layer', '175'],
          paint: {
            'line-color': '#ff0',
            'line-width': 1,
            'line-opacity': 0.7
          }
        },
        {
          id: 'flood_fill',
          type: 'fill',
          source: null,
          'source-layer': 'flood',
          filter: ['==', 'layer', '175'],
          paint: {
            'fill-color': '#75cff0',
            'fill-opacity': 0.7
          }
        },
        {
          id: 'flood_line',
          type: 'line',
          source: null,
          'source-layer': 'flood',
          filter: ['==', 'layer', '175'],
          paint: {
            'line-color': '#75cff0',
            'line-width': 1
          }
        }
      ]
    }
  }
}
