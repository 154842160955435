<template lang="pug">
.cloudFrame
  iframe(:src="url")
</template>

<script>
export default {
  props: ['id'],
  setup (props) {
    const url = `/cloud/pointcloud.html?cloud=${props.id}`
    return {
      url
    }
  }
}
</script>

<style lang="sass">
.cloudFrame
  position: relative
  overflow: hidden
  height: calc(100vh - 70px)
  iframe
    position: absolute
    border: 0
    outline: 0
    width: 100%
    height: 100%
</style>
