<template lang="pug">
header.header
  .header__inner
    .header__caption
      .header__logo
        a(href="/")
          img(src="@/assets/logo.svg")
      .header__title
        a(href="/").header__name
          b ГИС
          | #{' Крапивинская ГЭС'}
        .header__description Геопространственная информация
  .header__profil.profil
    .profil__user
      img(src="@/assets/profil.svg")
      span {{ profile.nameShort }}
    .profil__logout
      a(class="button button--logout" @click.prevent="logout") Выйти
</template>

<script>
import { computed } from 'vue'
import { AUTH_LOGOUT } from '@/store/actions/auth'
import { store } from '@/store'
import router from '@/router'

export default {
  setup () {
    const profile = computed(() => {
      return { ...store.getters.getProfil }
    })
    const logout = () => {
      store.dispatch(AUTH_LOGOUT)
      router.push('/login')
    }
    return {
      profile,
      logout
    }
  }
}
</script>
