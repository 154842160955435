<template lang="pug">
.map
  transition(name="fade")
    .my-toaster(v-if="loading")
      .loading.loading--s30
        .loading__animation
          div
      | Загрузка...
  slot(v-if="map")
    Info
    transition(name="fade")
      div(v-if="dataLoading")
        Flood
        Control
  .map__container#map
</template>

<script>
import { useMap } from '@/js/use/map'
import 'mapbox-gl/dist/mapbox-gl.css'
import Info from '@/components/Info'
import Flood from '@/components/Flood'
import Control from '@/components/Control'
import { ref } from 'vue'
import { createToaster } from '@meforma/vue-toaster'
import { store } from '@/store'
import { useRoute } from 'vue-router'

export default {
  components: {
    Info,
    Flood,
    Control
  },
  setup () {
    const route = useRoute()
    const toast = createToaster({ position: 'bottom-right' })
    const loading = ref(true)
    store.subscribe(mutation => {
      if (mutation.type === 'SOURCES_GET') {
        loading.value = true
      }
      if (mutation.type === 'SOURCES_SUCCESS' || mutation.type === 'SOURCES_ERROR') {
        loading.value = false
      }
      if (mutation.type === 'SOURCES_ERROR') {
        setTimeout(() => {
          toast.error('Ошибка загрузки данных')
        }, 300)
      }
    })

    const getOptionsMap = () => {
      if (route.query.lat && route.query.lng) {
        const { lng, lat, zoom } = route.query
        return {
          center: [lng, lat],
          zoom: zoom ?? 15
        }
      }
      return {
        bounds: [
          [86.962991, 54.058319],
          [87.621615, 55.157036]
        ]
      }
    }

    return {
      loading,
      ...useMap({ container: 'map', options: getOptionsMap() })
    }
  }
}
</script>
