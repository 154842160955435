export const getTopo10Layer = () => {
  return {
    topo10: {
      legend: {
        name: 'Топоплан М 1:10000',
        color: 'none',
        unitedLayer: {
          id: 'topo',
          name: 'Топографические планы'
        }
      },
      visible: 'none',
      options: {
        type: 'vector',
        tiles: [`${window.location.origin}/data/ortho/10000-z20/{z}/{x}/{y}.pbf`],
        minzoom: 0,
        maxzoom: 20,
        bounds: [86.938272, 54.025734, 87.621068, 55.044577]
      },
      layers: [
        {
          id: 't10_road_line',
          type: 'line',
          source: null,
          'source-layer': 'road_line',
          paint: {
            'line-color': '#000000',
            'line-width': 1
          }
        },
        {
          id: 't10_rail',
          type: 'line',
          source: null,
          'source-layer': 'rail',
          paint: {
            'line-color': '#000000',
            'line-width': 1
          }
        },
        {
          id: 't10_rail_icon',
          type: 'symbol',
          source: null,
          'source-layer': 'rail',
          layout: {
            'symbol-placement': 'line',
            'symbol-spacing': 50,
            'icon-allow-overlap': false,
            'icon-image': 'line',
            'icon-size': 1,
            'icon-rotate': 90
          },
          minzoom: 13
        },
        {
          id: 't10_rail_text',
          type: 'symbol',
          source: null,
          'source-layer': 'rail',
          paint: {
            'text-color': '#000',
            'text-halo-color': '#fff',
            'text-halo-width': 1
          },
          layout: {
            'symbol-placement': 'line',
            'text-field': '{text}',
            'text-size': 12,
            'text-anchor': 'bottom',
            'text-ignore-placement': true,
            'text-allow-overlap': true
          }
        },
        {
          id: 't10_horizontal',
          type: 'line',
          source: null,
          'source-layer': 'horizontal',
          paint: {
            'line-color': '#a55200',
            'line-width': [
              'match',
              ['get', 'layer'],
              '1',
              1,
              '2',
              2,
              /* other */ 1
            ]
          }
        },
        {
          id: 't10_otkos',
          type: 'line',
          source: null,
          'source-layer': 'otkos',
          paint: {
            'line-color': '#a55200',
            'line-width': 1
          }
        },
        {
          id: 't10_h_point_circle',
          type: 'circle',
          source: null,
          'source-layer': 'h_point',
          paint: {
            'circle-radius': 4,
            'circle-color': '#000000'
          },
          layout: {
            'circle-sort-key': 0
          }
        },
        {
          id: 't10_h_point_text',
          type: 'symbol',
          source: null,
          'source-layer': 'h_point',
          layout: {
            'text-field': '{h}',
            'text-size': 12,
            'text-variable-anchor': ['top-left', 'top-right', 'bottom-left', 'bottom-right'],
            'text-radial-offset': 0.3,
            'text-ignore-placement': true,
            'text-allow-overlap': true
          },
          paint: {
            'text-halo-color': '#fff',
            'text-halo-width': 1
          }
        },
        // river
        {
          id: 't10_river_line',
          type: 'line',
          source: null,
          'source-layer': 'river_line',
          paint: {
            'line-width': 1,
            'line-color': '#0000ff'
          }
        },
        {
          id: 't10_river_h_circle',
          type: 'circle',
          source: null,
          'source-layer': 'river_h',
          paint: {
            'circle-radius': 10,
            'circle-color': 'transparent',
            'circle-stroke-width': 1,
            'circle-stroke-color': '#0000ff'
          }
        },
        {
          id: 't10_river_h_text',
          type: 'symbol',
          source: null,
          'source-layer': 'river_h',
          paint: {
            'text-halo-color': '#fff',
            'text-halo-width': 1
          },
          layout: {
            'text-field': '{h}',
            'text-size': 14,
            'text-variable-anchor': ['top-left', 'top-right', 'bottom-left', 'bottom-right'],
            'text-radial-offset': 1,
            'text-ignore-placement': true,
            'text-allow-overlap': true
          }
        },
        {
          id: 't10_swamp_line',
          type: 'line',
          source: null,
          'source-layer': 'swamp_line',
          paint: {
            'line-color': '#0000ff',
            'line-width': 1
          }
        },
        {
          id: 't10_swamp_polygon',
          type: 'fill',
          source: null,
          'source-layer': 'swamp_polygon',
          paint: {
            'fill-pattern': 'foliage'
          }
        },
        // // Здания
        {
          id: 't10_building_line',
          type: 'line',
          source: null,
          'source-layer': 'building_line',
          paint: {
            'line-color': '#000000',
            'line-width': 1
          }
        },
        {
          id: 't10_building_fill',
          type: 'fill',
          source: null,
          'source-layer': 'building',
          paint: {
            'fill-color': '#000000'
          }
        },
        {
          id: 't10_bridge',
          type: 'line',
          source: null,
          'source-layer': 'bridge',
          paint: {
            'line-color': '#000000',
            'line-width': 1
          }
        },
        {
          id: 't10_water_object',
          type: 'line',
          source: null,
          'source-layer': 'water_object',
          paint: {
            'line-color': '#000000',
            'line-width': 1
          }
        },
        // // ЛЭП
        {
          id: 't10_lep_line',
          type: 'line',
          source: null,
          'source-layer': 'lep',
          paint: {
            'line-color': '#000000',
            'line-width': 1
          }
        },
        {
          id: 't10_lep_icon',
          type: 'symbol',
          source: null,
          'source-layer': 'lep',
          layout: {
            'symbol-placement': 'line',
            'symbol-spacing': 50,
            'icon-allow-overlap': false,
            'icon-image': 'square',
            'icon-size': 0.5
          },
          minzoom: 13
        },
        // Растительность
        {
          id: 't10_foliage_line',
          type: 'line',
          source: null,
          'source-layer': 'foliage',
          filter: ['!=', 'layer', 'dot'],
          paint: {
            'line-color': '#000',
            'line-width': 1
          }
        },
        {
          id: 't10_foliage_linedot',
          type: 'line',
          source: null,
          'source-layer': 'foliage',
          filter: ['==', 'layer', 'dot'],
          layout: {
            'line-cap': 'round'
          },
          paint: {
            'line-color': '#000',
            'line-width': 2,
            'line-dasharray': [0, 2]
          }
        },
        {
          id: 't10_graveyards_line',
          type: 'line',
          source: null,
          'source-layer': 'graveyards',
          paint: {
            'line-color': '#000',
            'line-width': 1.5
          }
        },
        {
          id: 't10_graveyards',
          type: 'fill',
          source: null,
          'source-layer': 'graveyards',
          paint: {
            'fill-pattern': 'graveyard'
          }
        }
      ]
    }
  }
}
