export const getTopo25Layer = () => {
  return {
    topo25: {
      legend: {
        name: 'Топоплан М 1:25000',
        color: 'none',
        unitedLayer: {
          id: 'topo',
          name: 'Топографические планы'
        }
      },
      visible: 'none',
      options: {
        type: 'vector',
        tiles: [`${window.location.origin}/data/ortho/25000-z20/{z}/{x}/{y}.pbf`],
        minzoom: 0,
        maxzoom: 20,
        bounds: [86.939019, 54.025333, 87.628859, 55.180431]
      },
      layers: [
        {
          id: 'road_line',
          type: 'line',
          source: null,
          'source-layer': 'road_line',
          paint: {
            'line-color': '#000000',
            'line-width': 1
          }
        },
        {
          id: 'road_point',
          type: 'symbol',
          source: null,
          'source-layer': 'road_point',
          paint: {
            'text-halo-color': 'rgba(255, 255, 255, 0.5)',
            'text-halo-width': 1
          },
          layout: {
            'text-field': '{text}',
            'text-anchor': 'center',
            'text-size': 12
          }
        },
        {
          id: 'road_polygon',
          type: 'fill',
          source: null,
          'source-layer': 'road_polygon',
          paint: {
            'fill-color': '#ffbf7f',
            'fill-opacity': 1
          }
        },
        {
          id: 'road_polygon_line',
          type: 'line',
          source: null,
          'source-layer': 'road_polygon',
          paint: {
            'line-color': '#000000',
            'line-width': 0.25
          }
        },
        {
          id: 'horizontal',
          type: 'line',
          source: null,
          'source-layer': 'horizontal',
          paint: {
            'line-color': '#a55200',
            'line-width': [
              'match',
              ['get', 'layer'],
              '1',
              1,
              '2',
              2,
              /* other */ 1
            ]
          }
        },
        {
          id: 'h_point_circle',
          type: 'circle',
          source: null,
          'source-layer': 'h_point',
          paint: {
            'circle-radius': 4,
            'circle-color': '#000000'
          },
          layout: {
            'circle-sort-key': 100
          }
        },
        {
          id: 'h_point_text',
          type: 'symbol',
          source: null,
          'source-layer': 'h_point',
          layout: {
            'text-field': '{h}',
            'text-size': 12,
            'text-variable-anchor': ['top-left', 'top-right', 'bottom-left', 'bottom-right'],
            'text-radial-offset': 0.3,
            'text-ignore-placement': true,
            'text-allow-overlap': true
          },
          paint: {
            'text-halo-color': 'rgba(255, 255, 255, 0.5)',
            'text-halo-width': 1
          }
        },
        // river
        {
          id: 'river_line',
          type: 'line',
          source: null,
          'source-layer': 'river_line',
          paint: {
            'line-width': [
              'match',
              ['get', 'layer'],
              'Polyline',
              1,
              'Mline',
              5,
              /* other */ 1
            ],
            'line-color': [
              'match',
              ['get', 'layer'],
              'Polyline',
              '#0000ff',
              'Mline',
              '#00ffff',
              /* other */ '#ff0000'
            ]
          }
        },
        {
          id: 'river_line_text',
          type: 'symbol',
          source: null,
          'source-layer': 'river_line',
          paint: {
            'text-color': '#0000ff',
            'text-halo-color': 'rgba(255, 255, 255, 0.5)',
            'text-halo-width': 1
          },
          layout: {
            'symbol-placement': 'line',
            'text-field': '{text}',
            'text-size': 16,
            'text-anchor': 'top',
            'text-ignore-placement': true,
            'text-allow-overlap': true
          }
        },
        {
          id: 'river_island',
          type: 'symbol',
          source: null,
          'source-layer': 'river_island',
          paint: {
            'text-halo-color': 'rgba(255, 255, 255, 0.5)',
            'text-halo-width': 1
          },
          layout: {
            'text-field': '{text}',
            'text-size': 12,
            'text-anchor': 'center'
          }
        },
        {
          id: 'river_h_circle',
          type: 'circle',
          source: null,
          'source-layer': 'river_h',
          paint: {
            'circle-radius': 10,
            'circle-color': 'transparent',
            'circle-stroke-width': 1,
            'circle-stroke-color': '#0000ff'
          }
        },
        {
          id: 'river_h_text',
          type: 'symbol',
          source: null,
          'source-layer': 'river_h',
          paint: {
            'text-halo-color': 'rgba(255, 255, 255, 0.5)',
            'text-halo-width': 1
          },
          layout: {
            'text-field': '{h}',
            'text-size': 14,
            'text-variable-anchor': ['top-left', 'top-right', 'bottom-left', 'bottom-right'],
            'text-radial-offset': 1,
            'text-ignore-placement': true,
            'text-allow-overlap': true
          }
        },
        {
          id: 'swamp_line',
          type: 'line',
          source: null,
          'source-layer': 'swamp_line',
          paint: {
            'line-color': '#0000ff',
            'line-width': 1
          }
        },
        // Объекты
        {
          id: 'objects',
          type: 'line',
          source: null,
          'source-layer': 'objects',
          paint: {
            'line-width': [
              'match',
              ['get', 'layer'],
              'bridge',
              1,
              'construction',
              3,
              /* other */ 1
            ],
            'line-color': '#000000'
          }
        },
        // Здания
        {
          id: 'building_line',
          type: 'line',
          source: null,
          'source-layer': 'building_line',
          paint: {
            'line-color': '#000000',
            'line-width': 1
          }
        },
        {
          id: 'building_point',
          type: 'symbol',
          source: null,
          'source-layer': 'building_point',
          paint: {
            'text-halo-color': 'rgba(255, 255, 255, 0.5)',
            'text-halo-width': 1
          },
          layout: {
            'text-field': '{text}',
            'text-size': 12,
            'text-anchor': 'center'
          }
        },
        {
          id: 'building_fill',
          type: 'fill',
          source: null,
          'source-layer': 'building',
          paint: {
            'fill-color': '#000000'
          }
        },
        // Разрез
        {
          id: 'razrez',
          type: 'line',
          source: null,
          'source-layer': 'razrez',
          paint: {
            'line-color': '#a55200',
            'line-width': 0.5
          }
        },
        // ЛЭП
        {
          id: 'lep_line',
          type: 'line',
          source: null,
          'source-layer': 'lep',
          paint: {
            'line-color': '#000000',
            'line-width': 1
          }
        },
        {
          id: 'lep_icon',
          type: 'symbol',
          source: null,
          'source-layer': 'lep',
          layout: {
            'symbol-placement': 'line',
            'symbol-spacing': 50,
            'icon-allow-overlap': false,
            'icon-image': 'square',
            'icon-size': 0.5
          },
          minzoom: 13
        },
        // Растительность
        {
          id: 'foliage_line',
          type: 'line',
          source: null,
          'source-layer': 'foliage_line',
          paint: {
            'line-color': [
              'match',
              ['get', 'layer'],
              'black',
              '#000000',
              'orange',
              '#ff7f00',
              'brown',
              '#a55200',
              /* other */ '#ff0000'
            ],
            'line-width': 1
          }
        },
        {
          id: 'foliage_polygon',
          type: 'fill',
          source: null,
          'source-layer': 'foliage_polygon',
          paint: {
            'fill-pattern': 'foliage'
          }
        },
        {
          id: 'graveyards_line',
          type: 'line',
          source: null,
          'source-layer': 'graveyards',
          paint: {
            'line-color': '#000',
            'line-width': 1.5
          }
        },
        {
          id: 'graveyards',
          type: 'fill',
          source: null,
          'source-layer': 'graveyards',
          paint: {
            'fill-pattern': 'graveyard'
          }
        }
      ]
    }
  }
}
