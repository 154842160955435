<template lang="pug">
transition(name="fade")
  .route-container
    .route-container__close(@click="routeClose")
      svg(width="11" height="11" viewBox="0 0 11 11")
        path(d="M10 1.12763L1.07006 10M1 1L9.92994 9.87237" stroke="#F28254")
    .route-container__point(@click="drawMode('A')")
      .route-container__icon
        svg(width="22" height="22" viewBox="0 0 22 22")
          circle(cx="11" cy="11" r="11" fill="#F28254")
          path(d="M8.84091 16L9.39962 14.0859H12.6004L13.1591 16H16L12.7992 6H9.20076L6 16H8.84091ZM9.99621 12.0547L10.9621 8.75391H11.0379L12.0038 12.0547H9.99621Z" fill="white")
      input(type="text" readonly="readonly" placeholder="Широта, долгота"
        :class="{focus: route.draw === 'A'}"
        :value="coordinates.A"
      )
    .route-container__point(@click="drawMode('B')")
      .route-container__icon
        svg(width="22" height="22" viewBox="0 0 22 22")
          circle(cx="11" cy="11" r="11" fill="#F28254")
          path(d="M7 16H11.5796C13.6936 16 15 14.8135 15 13.168C15 11.7568 13.9881 10.834 12.7387 10.7852V10.6875C13.8646 10.458 14.62 9.60352 14.62 8.5C14.62 6.97656 13.4418 6 11.2185 6H7V16ZM9.64133 13.832V11.7227H10.9145C11.7173 11.7227 12.2067 12.1133 12.2067 12.8164C12.2067 13.4854 11.7648 13.832 10.8765 13.832H9.64133ZM9.64133 10.043V8.12891H10.7625C11.4181 8.12891 11.9026 8.4707 11.9026 9.08594C11.9026 9.70117 11.4181 10.043 10.7245 10.043H9.64133Z" fill="white")
      input(type="text" readonly="readonly" placeholder="Широта, долгота"
        :class="{focus:  route.draw === 'B'}"
        :value="coordinates.B"
      )
    .route-container__bottom(v-if="route.distance")
      .route-container__measure
        span В дороге
        b {{ route.duration }}
      .route-container__measure
        span Расстояние
        b {{ route.distance }}
</template>

<script>
import { useRoute } from '@/js/use/route'

export default {
  emits: ['routeClose'],
  setup () {
    return {
      ...useRoute()
    }
  }
}
</script>
