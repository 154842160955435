const orthoAr = [
  '/ortho/okn/Ajendarovo 1.2/Ajendarovo 1 (Orto) MSK 42 z2',
  '/ortho/okn/Ajendarovo 1.2/Ajendarovo 2 (Orto) MSK 42 z2',
  '/ortho/okn/Atuktashskaya stoyanka/Atuktashskaya stoyanka (orto) MSK 42 z2',
  '/ortho/okn/Chernoetapinskaya stoyanka/Chernoetapinskaya stoyanka (orto) MSK 42 z2',
  '/ortho/okn/Erunakovskaya stoyanka. Poselenie Erunakovo 2. Stoyanka Erunakovo 3/Erunakovo 1.2.3 (orto) MSK 42 z2',
  '/ortho/okn/Kazankovo 5/Kazankovo_5 (orto) MSK 42 z2',
  '/ortho/okn/Kurja 1.4.5.8/Kurja 1.4.5.8 (orto) MSK 42 z2',
  '/ortho/okn/Kurja 2.3.6.7/Kurja 2.3.6.7 (orto) MSK 42 z2',
  '/ortho/okn/Kurja 9/Kurja 9 (orto) MSK 42 z2',
  '/ortho/okn/Luchinovo 1.2.3.4/Luchinovo 1.2.3.4 (orto) MSK 42 z2',
  '/ortho/okn/Luchinovo 5/Luchinovo 5 (orto) MSK 42 z2',
  '/ortho/okn/Mogila Vachuchevicha/Mogila Vachuchevicha (orto) MSK 42 z2',
  '/ortho/okn/Narikskoe Poselenie/Narikskoe Poselenie (orto) MSK 42 z2',
  '/ortho/okn/Poselenie Beliy Etap/Poselenie Beliy Etap (orto) MSK 42 z2',
  '/ortho/okn/Poselenie Biche Gorlo. Poselenie Bungarap/Poselenie Biche Gorlo (orto) MSK 42 z2',
  '/ortho/okn/Poselenie Biche Gorlo. Poselenie Bungarap/Poselenie Bungarap (orto) MSK 42 z2',
  '/ortho/okn/Poselenie Bogdanovo 1.2/Poselenie Bogdanovo 1.2 (orto) MSK 42 z2',
  '/ortho/okn/Poselenie Erigino. Kovrizhskaya Stoyanka/Kovrizhskaya Stoyanka (orto) MSK 42 z2',
  '/ortho/okn/Poselenie Erigino. Kovrizhskaya Stoyanka/Poselenie Erigino (orto) MSK 42 z2',
  '/ortho/okn/Poselenie Glinka Stoyanka Nikolskoe/Poselenie Glinka (orto) MSK 42 z2',
  '/ortho/okn/Poselenie Glinka Stoyanka Nikolskoe/Stoyanka Nikolskoe (orto) MSK 42 z2',
  '/ortho/okn/Poselenie Krasnoe Ozero/Poselenie Krasnoe Ozero (orto) MSK 42 z2',
  '/ortho/okn/Poselenie Liagushie/Poselenie Liagushie (orto) MSK 42 z2',
  '/ortho/okn/Poselenie Osinovoe Pleso/Poselenie Osinovoe Pleso (orto) MSK 42 z2',
  '/ortho/okn/Poselenie Ostiakovo/Poselenie Ostiakovo (orto) MSK 42 z2',
  '/ortho/okn/Poselenie Pegas/Poselenie Pegas (orto) MSK 42 z2',
  '/ortho/okn/Saltimakovo 1.2.3/Saltimakovo 1 (orto) MSK 42 z2',
  '/ortho/okn/Saltimakovo 1.2.3/Saltimakovo  2.3 (orto) MSK 42 z2',
  '/ortho/okn/Sosnovka 1.2.3.4/Sosnovka 1.2.3.4 (orto) MSK 42 z2',
  '/ortho/okn/Stoyanka Georgievka 1.2 Poselenie Kumina/Poselenie Kumina (orto) MSK 42 z2',
  '/ortho/okn/Stoyanka Georgievka 1.2 Poselenie Kumina/Stoyanka Georgievka 1.2 (orto) MSK 42 z2',
  '/ortho/okn/Stoyanka Ubik/Stoyanka Ubik (orto) MSK 42 z2',
  '/ortho/okn/Yachmeniyhskoe poselenie, Poselenie Yachmeniyha 2/Poselenie Yachmeniyha 2 (orto) MSK 42 z2',
  '/ortho/okn/Yachmeniyhskoe poselenie, Poselenie Yachmeniyha 2/Yachmeniyhskoe poselenie (orto) MSK 42 z2',
  '/ortho/cattle/Poligon Krasnoznamenka',
  '/ortho/cattle/Poligon Osinovoe plesso'
]

const getBounds = async url => {
  const response = await fetch(`${url}/bounds.json`)
  const { bbox } = await response.json()
  return bbox
}

export const getOrthoLayers = async () => {
  const orthoLayers = {}
  for (const index in orthoAr) {
    const id = `ortho_${index}`
    const url = `${window.location.origin}/data${orthoAr[index]}`
    const bounds = await getBounds(url)
    orthoLayers[id] = {
      options: {
        type: 'raster',
        tiles: [`${url}/{z}/{x}/{y}.png`],
        tileSize: 256,
        bounds,
        maxzoom: 20
      },
      layers: [
        {
          id,
          type: 'raster',
          source: id
        }
      ]
    }
  }
  return orthoLayers
}
