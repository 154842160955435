export const getGesTopoLayer = () => {
  return {
    gesTopo: {
      legend: {
        name: 'Топосъемка',
        color: 'none',
        unitedLayer: {
          id: 'ges',
          name: 'Ситуационный план гидроузла'
        }
      },
      visible: 'none',
      options: {
        type: 'vector',
        tiles: [`${window.location.origin}/data/vector/topo_topo-z20/{z}/{x}/{y}.pbf`],
        minzoom: 0,
        maxzoom: 20,
        bounds: [86.999197, 55.009744, 87.030369, 55.026627]
      },
      layers: [
        {
          id: 'gt_coastline',
          type: 'line',
          source: null,
          'source-layer': 'coastline',
          paint: {
            'line-color': '#3cad95',
            'line-width': 1.5
          }
        },
        {
          id: 'gt_shafts',
          type: 'fill',
          source: null,
          'source-layer': 'shafts',
          paint: {
            'fill-color': '#000'
          }
        },
        {
          id: 'gt_bief_upper',
          type: 'line',
          source: null,
          'source-layer': 'bief_upper',
          paint: {
            'line-color': '#000',
            'line-width': 1.5
          }
        },
        {
          id: 'gt_bief_upper_symbol',
          type: 'symbol',
          source: null,
          'source-layer': 'bief_upper',
          layout: {
            'symbol-placement': 'line',
            'symbol-spacing': 10,
            'icon-rotate': 90,
            'icon-allow-overlap': true,
            'icon-image': 'line',
            'icon-size': 1,
            'icon-offset': [6, 0]
          }
        },
        {
          id: 'gt_bief_lower',
          type: 'line',
          source: null,
          'source-layer': 'bief_lower',
          paint: {
            'line-color': '#000',
            'line-dasharray': [1, 4],
            'line-width': 1.5
          }
        },
        {
          id: 'gt_horizontal',
          type: 'line',
          source: null,
          'source-layer': 'horizontal',
          paint: {
            'line-color': '#a55200',
            'line-width': 1.5
          }
        },
        {
          id: 'gt_border',
          type: 'line',
          source: null,
          'source-layer': 'border',
          paint: {
            'line-color': '#000',
            'line-dasharray': [4, 4],
            'line-width': 1.5
          }
        },
        {
          id: 'gt_border_form_relief',
          type: 'line',
          source: null,
          'source-layer': 'border_form_relief',
          paint: {
            'line-color': '#000',
            'line-dasharray': [1, 5],
            'line-width': 1.5
          }
        },
        {
          id: 'gt_border_form',
          type: 'line',
          source: null,
          'source-layer': 'border_form',
          paint: {
            'line-color': '#000',
            'line-dasharray': [1, 5],
            'line-width': 1.5
          }
        },
        {
          id: 'gt_road',
          type: 'line',
          source: null,
          'source-layer': 'road',
          paint: {
            'line-color': '#000',
            'line-width': 1.5
          }
        },
        {
          id: 'gt_fence_concrete',
          type: 'line',
          source: null,
          'source-layer': 'fence_concrete',
          paint: {
            'line-color': '#000',
            'line-gap-width': 3,
            'line-width': 1.5
          }
        },
        {
          id: 'gt_fence_concrete_symbol',
          type: 'symbol',
          source: null,
          'source-layer': 'fence_concrete',
          layout: {
            'symbol-placement': 'line',
            'symbol-spacing': 20,
            'icon-allow-overlap': true,
            'icon-image': 'line-short'
          }
        },
        {
          id: 'gt_fence_wire',
          type: 'line',
          source: null,
          'source-layer': 'fence_wire',
          paint: {
            'line-color': '#000',
            'line-width': 1.5
          }
        },
        {
          id: 'gt_fence_wire_symbol',
          type: 'symbol',
          source: null,
          'source-layer': 'fence_wire',
          layout: {
            'symbol-placement': 'line',
            'symbol-spacing': 8,
            'icon-rotate': 90,
            'icon-allow-overlap': true,
            'icon-image': 'line',
            'icon-size': 1,
            'icon-offset': [6, 0]
          }
        },
        {
          id: 'gt_building',
          type: 'line',
          source: null,
          'source-layer': 'building',
          paint: {
            'line-color': '#000',
            'line-width': 1.5
          }
        },
        {
          id: 'gt_isohypse',
          type: 'line',
          source: null,
          'source-layer': 'isohypse',
          paint: {
            'line-color': '#3cd0d5',
            'line-width': 1.5
          }
        },
        {
          id: 'gt_forest',
          type: 'line',
          source: null,
          'source-layer': 'forest',
          paint: {
            'line-color': '#000',
            'line-width': 1.5
          }
        },
        {
          id: 'gt_lep',
          type: 'line',
          source: null,
          'source-layer': 'lep',
          paint: {
            'line-color': '#000',
            'line-width': 1.5
          }
        },
        {
          id: 'gt_threshold',
          type: 'fill',
          source: null,
          'source-layer': 'threshold',
          paint: {
            'fill-color': '#956600'
          }
        },
        {
          id: 'gt_channel',
          type: 'line',
          source: null,
          'source-layer': 'channel',
          paint: {
            'line-color': '#3cad95',
            'line-dasharray': [5, 2],
            'line-width': 1.5
          }
        },
        {
          id: 'gt_hayfield',
          type: 'line',
          source: null,
          'source-layer': 'hayfield',
          paint: {
            'line-color': '#3cad58',
            'line-width': 1.5
          }
        },
        {
          id: 'gt_h_circle',
          type: 'circle',
          source: null,
          'source-layer': 'h',
          paint: {
            'circle-pitch-scale': 'viewport',
            'circle-radius': 4,
            'circle-color': '#000000'
          }
        },
        {
          id: 'gt_h_text',
          type: 'symbol',
          source: null,
          'source-layer': 'h',
          layout: {
            'text-field': '{text}',
            'text-size': 12,
            'text-variable-anchor': ['top-left', 'top-right', 'bottom-left', 'bottom-right'],
            'text-radial-offset': 0.3,
            'text-allow-overlap': true,
            'text-ignore-placement': false
          },
          paint: {
            'text-halo-color': 'rgba(255, 255, 255, 0.5)',
            'text-halo-width': 1
          },
          minzoom: 10
        }
      ]
    },
    gesProject: {
      legend: {
        name: 'Проект строительства',
        color: 'none',
        unitedLayer: {
          id: 'ges',
          name: 'Ситационный план гидроузла'
        }
      },
      visible: 'none',
      options: {
        type: 'vector',
        tiles: [`${window.location.origin}/data/vector/topo_project-z20/{z}/{x}/{y}.pbf`],
        minzoom: 0,
        maxzoom: 20,
        bounds: [86.958109, 55.012439, 87.033083, 55.027665]
      },
      layers: [
        {
          id: 'gp_metal',
          type: 'line',
          source: null,
          'source-layer': 'metal',
          paint: {
            'line-color': '#000',
            'line-width': 1.5
          }
        },
        {
          id: 'gp_concrete',
          type: 'line',
          source: null,
          'source-layer': 'concrete',
          paint: {
            'line-color': '#000',
            'line-width': 1.5
          }
        },
        {
          id: 'gp_water',
          type: 'line',
          source: null,
          'source-layer': 'water',
          paint: {
            'line-color': '#007ad2',
            'line-width': 2
          }
        },
        {
          id: 'gp_grunt',
          type: 'line',
          source: null,
          'source-layer': 'grunt',
          paint: {
            'line-color': '#000',
            'line-width': 1.5
          }
        },
        {
          id: 'gp_road',
          type: 'line',
          source: null,
          'source-layer': 'road',
          paint: {
            'line-color': '#000',
            'line-width': 1.5
          }
        },
        {
          id: 'gp_building',
          type: 'line',
          source: null,
          'source-layer': 'building',
          paint: {
            'line-color': '#000',
            'line-width': 1.5
          }
        },
        {
          id: 'gp_otkos',
          type: 'line',
          source: null,
          'source-layer': 'otkos',
          paint: {
            'line-color': '#000',
            'line-width': 1.5
          }
        },
        {
          id: 'gp_unknown',
          type: 'line',
          source: null,
          'source-layer': 'unknown',
          paint: {
            'line-color': '#000',
            'line-width': 1.5
          }
        },
        {
          id: 'gp_facility',
          type: 'line',
          source: null,
          'source-layer': 'facility',
          paint: {
            'line-color': '#000',
            'line-width': 1.5
          }
        },
        {
          id: 'gp_split',
          type: 'line',
          source: null,
          'source-layer': 'split',
          paint: {
            'line-color': '#000',
            'line-width': 1.5
          }
        },
        {
          id: 'gp_crane',
          type: 'line',
          source: null,
          'source-layer': 'crane',
          paint: {
            'line-color': '#ff3300',
            'line-width': 1.5
          }
        },
        {
          id: 'gp_facility_axis',
          type: 'line',
          source: null,
          'source-layer': 'facility_axis',
          paint: {
            'line-color': [
              'match',
              ['get', 'color'],
              'brown',
              '#674427',
              'darkgreen',
              '#4f9c4f',
              'green',
              '#00ff00',
              /* other */ '#000'
            ],
            'line-width': 1.5
          }
        },
        {
          id: 'gp_split_axis',
          type: 'line',
          source: null,
          'source-layer': 'split_axis',
          paint: {
            'line-color': [
              'match',
              ['get', 'color'],
              'purple',
              '#c45dcb',
              /* other */ '#000'
            ],
            'line-width': 1.5,
            'line-dasharray': [10, 2]
          }
        },
        {
          id: 'gp_pivot',
          type: 'circle',
          source: null,
          'source-layer': 'pivot',
          paint: {
            'circle-radius': 8,
            'circle-color': '#54b04a'
          }
        },
        {
          id: 'gp_pivot_text',
          type: 'symbol',
          source: null,
          'source-layer': 'pivot',
          layout: {
            'text-field': '{text}',
            'text-size': 14,
            'text-variable-anchor': ['right', 'left', 'top-left', 'top-right', 'bottom-left', 'bottom-right'],
            'text-radial-offset': 1,
            'text-allow-overlap': true,
            'text-ignore-placement': false
          },
          paint: {
            'text-halo-color': 'rgba(255, 255, 255, 0.5)',
            'text-halo-width': 1
          },
          minzoom: 10
        },
        {
          id: 'gp_construction',
          type: 'circle',
          source: null,
          'source-layer': 'construction',
          paint: {
            'circle-radius': 4,
            'circle-color': '#000'
          }
        },
        {
          id: 'gp_construction_text',
          type: 'symbol',
          source: null,
          'source-layer': 'construction',
          layout: {
            'text-field': '{text}',
            'text-size': 14,
            'text-variable-anchor': ['right', 'left'],
            'text-radial-offset': 1,
            'text-allow-overlap': true,
            'text-ignore-placement': false
          },
          paint: {
            'text-halo-color': 'rgba(255, 255, 255, 0.5)',
            'text-halo-width': 1
          },
          minzoom: 10
        },
        {
          id: 'gp_piket',
          type: 'circle',
          source: null,
          'source-layer': 'piket',
          paint: {
            'circle-radius': 8,
            'circle-color': '#000'
          }
        },
        {
          id: 'gp_piket_text',
          type: 'symbol',
          source: null,
          'source-layer': 'piket',
          layout: {
            'text-field': '{text}',
            'text-size': 14,
            'text-variable-anchor': ['right', 'left'],
            'text-radial-offset': 1,
            'text-allow-overlap': true,
            'text-ignore-placement': false
          },
          paint: {
            'text-halo-color': 'rgba(255, 255, 255, 0.5)',
            'text-halo-width': 1
          },
          minzoom: 10
        },
        {
          id: 'gp_note',
          type: 'circle',
          source: null,
          'source-layer': 'note',
          paint: {
            'circle-radius': 8,
            'circle-color': '#db1e2a'
          },
          options: {
            hover: 'popup',
            popup: {
              text: '',
              coordinates: 'Координаты'
            } // first prop - title popup
          }
        },
        {
          id: 'gp_slope',
          type: 'symbol',
          source: null,
          'source-layer': 'slope',
          layout: {
            'text-field': '{text}',
            'text-size': 14,
            'text-allow-overlap': true,
            'text-ignore-placement': false
          },
          paint: {
            'text-halo-color': 'rgba(255, 255, 255, 0.5)',
            'text-halo-width': 1
          },
          minzoom: 10
        }
      ]
    }
  }
}
