import { createWebHistory, createRouter } from 'vue-router'
import Auth from '@/views/Auth.vue'
import Map from '@/views/Map.vue'
import Cloud from '../views/Cloud'
import { store } from '@/store'

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next()
    return
  }
  next('/')
}

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next()
    return
  }
  next('/login')
}

const routes = [
  {
    path: '/login',
    name: 'Авторизация',
    component: Auth,
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/',
    name: 'Карта',
    component: Map,
    meta: {
      breadcrumb: 'Карта'
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: '/cloud/:id',
    name: 'Трехмерное облако точек',
    props: true,
    component: Cloud,
    meta: {
      breadcrumb: function () {
        const { name } = this.$route
        return `${name}`
      }
    },
    beforeEnter: ifAuthenticated
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  document.title = (to.path === '/')
    ? process.env.VUE_APP_NAME
    : `${to.name} | ${process.env.VUE_APP_NAME}`
  next()
})

export default router
