<template lang="pug">
.flood
  .flood__title Уровень воды в верхнем бьефе плотины, м
  .flood__inner
    .flood__caption.flood__caption--left Над уровнем моря
    .flood__caption.flood__caption--right Относительно рельефа
    .flood__level.flood-level
      Slider(v-model="flood.value" v-bind="flood")
      .flood-level__labels
        .flood-level__label(
          v-for="(label, i) in floodValues"
          :key="i"
          :class="{ bold: label.flood_h === '175'}"
        )
          span {{ label.flood_h }}
          span {{ label.flood_r }}
  div(v-show="visible")
    .flood__measuring.flood__measuring--wide
      span Площадь зеркала водохранилища
      b {{ floodCurrent.s }} га
    .flood__measuring
      span Площадь затопленного леса
      b {{ floodCurrent.forest_s }} га
    .flood__measuring
      span Объем затопленного леса
      b {{ floodCurrent.forest_v }} м
        sup 3
  .flood__toggleButton(@click="toggle" :class="{active: visible}")
    svg(width="13" height="7" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg")
      path(fill-rule="evenodd" clip-rule="evenodd" d="M5.59923 0.740743C5.97372 0.419752 6.52632 0.419752 6.90081 0.740743L12.1508 5.24074C12.5701 5.60017 12.6187 6.23147 12.2593 6.65079C11.8999 7.07012 11.2686 7.11868 10.8492 6.75926L6.25002 2.81708L1.65081 6.75926C1.23148 7.11868 0.600184 7.07012 0.240762 6.65079C-0.118661 6.23147 -0.0700991 5.60017 0.349227 5.24074L5.59923 0.740743Z")

</template>

<script>
import Slider from '@vueform/slider'
import { ref, reactive, watch, inject } from 'vue'
import { useToggle } from '../js/use/toggle'

export default {
  components: {
    Slider
  },
  setup () {
    const map = inject('map')
    const floodValues = ref([
      { flood_h: '130', flood_r: 0, s: null, forest_s: null, forest_v: null }, // 0
      { flood_h: '160', flood_r: 30, s: null, forest_s: null, forest_v: null }, // 1
      { flood_h: '175', flood_r: 45, s: null, forest_s: null, forest_v: null }, // 2
      { flood_h: '177.5', flood_r: 47.5, s: null, forest_s: null, forest_v: null }, // 3
      { flood_h: '180', flood_r: 50, s: null, forest_s: null, forest_v: null } // 4
    ])
    const floodCurrent = ref({})
    const flood = reactive({
      value: 2,
      min: 0,
      max: floodValues.value.length - 1,
      orientation: 'vertical',
      direction: 'rtl'
    })
    const getFloodParams = () => {
      const features = map.value.querySourceFeatures('flood', { sourceLayer: 'flood' })
      if (features.length) {
        floodValues.value.forEach((value, index) => {
          const feature = features.find(item => item.properties.layer === value.flood_h) || null
          if (feature !== null && value.s === null) {
            const formatNum = new Intl.NumberFormat('ru-RU')
            floodValues.value[index].s = formatNum.format(feature.properties.s_ga)
            floodValues.value[index].forest_s = formatNum.format(Math.round(feature.properties.forest_s_m / 10_000))
            floodValues.value[index].forest_v = formatNum.format(Math.round(feature.properties.forest_v_m))
          }
        })
      }
    }
    map.value.on('data', (e) => {
      const notLoadedParams = !!floodValues.value.filter(param => param.s === null).length
      if (!notLoadedParams) {
        floodCurrent.value = Object.assign({}, floodValues.value[flood.value])
        return
      }
      if (e.sourceId === 'flood') {
        getFloodParams()
      }
    })
    const changeFlood = watch(flood, (oldVal, newVal) => {
      floodCurrent.value = Object.assign({}, floodValues.value[newVal.value])
      const h = floodValues.value[newVal.value].flood_h
      map.value.setFilter('flood_fill', ['==', 'layer', h])
      map.value.setFilter('flood_line', ['==', 'layer', h])
      map.value.setFilter('flood_20m', ['==', 'layer', h])
      map.value.setFilter('flood_200m', ['==', 'layer', h])
      map.value.setFilter('oopt_new_fill', ['==', 'flood_h', h * 1])
      map.value.setFilter('oopt_new_line', ['==', 'flood_h', h * 1])
    })
    return {
      ...useToggle(false), floodValues, flood, changeFlood, floodCurrent
    }
  }
}
</script>
