import { computed, getCurrentInstance, inject, onUnmounted, reactive } from 'vue'
import mapboxgl from 'mapbox-gl'
import { routingCar } from '@/js/utils/api'
import { featureCollection } from '@turf/turf'
import { createToaster } from '@meforma/vue-toaster'

export const useRoute = () => {
  const { emit } = getCurrentInstance()
  const toast = createToaster()
  const map = inject('map')
  const drawModeOn = inject('drawModeOn')
  const drawModeOff = inject('drawModeOff')
  const markerA = document.createElement('div')
  markerA.className = 'route-marker-A'
  const markerB = document.createElement('div')
  markerB.className = 'route-marker-B'
  const route = reactive({
    draw: null,
    A: {
      add: false,
      marker: new mapboxgl.Marker(markerA, { draggable: true, anchor: 'bottom' }),
      coordinates: null
    },
    B: {
      add: false,
      marker: new mapboxgl.Marker(markerB, { draggable: true, anchor: 'bottom' }),
      coordinates: null
    },
    distance: null,
    duration: null
  })
  const drawMode = mode => {
    route.draw = mode
    if (mode) {
      drawModeOn()
    } else {
      drawModeOff()
    }
  }
  drawMode('A')
  const coordinates = computed(() => {
    const temp = { A: null, B: null }
    Object.keys(temp).forEach(point => {
      temp[point] = route[point].coordinates
        ? route[point].coordinates.map(coord => Math.round(coord * 10 ** 6) / 10 ** 6)
        : null
    })
    return temp
  })
  map.value.on('mousemove', e => {
    if (!route.draw) {
      return
    }
    if (!route[route.draw].add) {
      route[route.draw].marker.setLngLat(e.lngLat).addTo(map.value)
      route[route.draw].add = true
    } else {
      route[route.draw].marker.setLngLat(e.lngLat)
    }
  })
  const createRoute = (coordinates) => {
    if (!route.draw) {
      return
    }
    route[route.draw].coordinates = coordinates
    // if (!route.B.coordinates) {
    //   route.draw = 'B'
    //   return
    // }
    drawMode(null)
    if (route.A.coordinates && route.B.coordinates) {
      routingCar([
        route.A.coordinates,
        route.B.coordinates
      ]).then(resp => {
        const { features } = resp
        const { distance, duration } = features[0].properties.summary
        route.duration = secondsToTime(duration)
        route.distance = distance > 1000
          ? Math.round(distance * 100 / 1000) / 100 + ' км'
          : distance + ' м'
        if (map.value.getSource('route')) {
          map.value.getSource('route').setData(resp)
        } else {
          map.value.addSource('route', {
            type: 'geojson',
            data: resp
          })
          map.value.addLayer({
            id: 'route',
            source: 'route',
            type: 'line',
            layout: {
              'line-join': 'round',
              'line-cap': 'round'
            },
            paint: {
              'line-color': '#F28254',
              'line-width': 3
            }
          })
          map.value.addLayer({
            id: 'routearrows',
            type: 'symbol',
            source: 'route',
            layout: {
              'symbol-placement': 'line',
              'text-field': '▶',
              'text-size': [
                'interpolate',
                ['linear'],
                ['zoom'],
                12, 24,
                22, 60
              ],
              'symbol-spacing': [
                'interpolate',
                ['linear'],
                ['zoom'],
                12, 30,
                22, 160
              ],
              'text-keep-upright': false
            },
            paint: {
              'text-color': '#F28254',
              'text-halo-color': 'hsl(55, 11%, 96%)',
              'text-halo-width': 2
            }
          })
        }
      }).catch(() => {
        toast.warning('Не удалось построить маршрут')
      })
    }
  }
  map.value.on('mouseup', e => createRoute(e.lngLat.toArray()))
  route.A.marker.on('dragend', () => {
    const coordinates = route.A.marker.getLngLat().toArray()
    drawMode('A')
    createRoute(coordinates)
  })
  route.B.marker.on('dragend', () => {
    const coordinates = route.B.marker.getLngLat().toArray()
    drawMode('B')
    createRoute(coordinates)
  })
  const routeReset = () => {
    drawMode(null)
    route.A.add = route.B.add = false
    route.B.coordinates = route.B.coordinates = null
    route.distance = null
    route.duration = null
    route.A.marker.remove()
    route.B.marker.remove()
    if (map.value.getSource('route')) {
      map.value.getSource('route').setData(featureCollection([]))
    }
  }
  const routeClose = () => {
    routeReset()
    emit('routeClose')
  }
  onUnmounted(() => routeReset())

  return {
    route,
    routeClose,
    routeReset,
    coordinates,
    drawMode
  }
}

function secondsToTime (duration) {
  const hours = Math.floor(duration / (60 * 60))
  const minutes = Math.floor(duration % (60 * 60) / 60)
  return `${hours} ч. ${minutes} мин.`
}
