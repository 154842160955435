<template lang="pug">
.info
    h1 характеристики ГЭС
    .info__showing.info__showing--coordinates Координаты: 55°01′13″ С.Ш. 87°01′30″ В.Д.
    .info__showing.info__showing--place северо-восточнее поселка Зеленогорский
    .info__toogle(v-if="visible")
      .info__showing
        b Мощность ГЭС:
        | 300 МВт
      .info__showing
        b Средняя выработка:
        | 1,89 млрд кВт&middot;ч
      .info__showing
        b Оборудование:
        | 3 гидроагрегата по 100 МВт
      .info__showing
        b Максимальный напор ГЭС:
        | 45,3 м
      .info__showing
        b Минимальный напор ГЭС:
        | 20,5 м
      .info__showing
        b Отметка НПУ:
        | 177,5 м
      .info__showing
        b Отметка УМО :
        | 154,7 м
      .info__showing
        b Плотина образует водохранилище объемом:
        | 11,71&nbsp;км³
    .info__toggleButton(@click="toggle" :class="{active: visible}")
      svg(width="13" height="7" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg")
        path(fill-rule="evenodd" clip-rule="evenodd" d="M5.59923 0.740743C5.97372 0.419752 6.52632 0.419752 6.90081 0.740743L12.1508 5.24074C12.5701 5.60017 12.6187 6.23147 12.2593 6.65079C11.8999 7.07012 11.2686 7.11868 10.8492 6.75926L6.25002 2.81708L1.65081 6.75926C1.23148 7.11868 0.600184 7.07012 0.240762 6.65079C-0.118661 6.23147 -0.0700991 5.60017 0.349227 5.24074L5.59923 0.740743Z")
</template>

<script>
import { useToggle } from '@/js/use/toggle'
export default {
  setup () {
    return {
      ...useToggle(false)
    }
  }
}
</script>
