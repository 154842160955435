<template lang="pug">
.auth
  .auth__wrap
    form.auth__form.authForm(@submit.prevent="login")
      .authForm__header
        h1.authForm__title
          span ГИС
          | #{ ' Крапивинская ГЭС'}
        .authForm__caption Геопространственная информация
      .authForm__body
        .authForm__field
          input(type="text" name="username" v-model="username" required placeholder="Логин")
        .authForm__field
          input(type="password" name="password" v-model="password" required placeholder="Пароль")
        .authForm__loading(v-if="authStatus === 'loading'")
          .loading
            .loading__animation
              div
        .authForm__error(v-if="authStatus === 'error'") Неверный логин или пароль
        .authForm__field
          button(type="submit") Войти
</template>

<script>
import { computed, ref } from 'vue'
import { AUTH_REQUEST } from '@/store/actions/auth'
import router from '@/router'
import { store } from '@/store'

export default {
  setup () {
    const username = ref('')
    const password = ref('')
    const login = () => {
      store.dispatch(AUTH_REQUEST, {
        login: username.value,
        password: password.value
      }).then(() => {
        router.push('/')
      })
    }
    return {
      username,
      password,
      login,
      authStatus: computed(() => store.getters.authStatus)
    }
  }
}
</script>
